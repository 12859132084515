// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alaskaosp-tsx": () => import("./../src/pages/alaskaosp.tsx" /* webpackChunkName: "component---src-pages-alaskaosp-tsx" */),
  "component---src-pages-binding-losun-tsx": () => import("./../src/pages/binding_losun.tsx" /* webpackChunkName: "component---src-pages-binding-losun-tsx" */),
  "component---src-pages-birki-tsx": () => import("./../src/pages/birki.tsx" /* webpackChunkName: "component---src-pages-birki-tsx" */),
  "component---src-pages-groska-tsx": () => import("./../src/pages/groska.tsx" /* webpackChunkName: "component---src-pages-groska-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landgerdir-tsx": () => import("./../src/pages/landgerdir.tsx" /* webpackChunkName: "component---src-pages-landgerdir-tsx" */),
  "component---src-pages-mat-tsx": () => import("./../src/pages/mat.tsx" /* webpackChunkName: "component---src-pages-mat-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-page-3-tsx": () => import("./../src/pages/page-3.tsx" /* webpackChunkName: "component---src-pages-page-3-tsx" */),
  "component---src-pages-russalerki-tsx": () => import("./../src/pages/russalerki.tsx" /* webpackChunkName: "component---src-pages-russalerki-tsx" */),
  "component---src-pages-sitkagreni-tsx": () => import("./../src/pages/sitkagreni.tsx" /* webpackChunkName: "component---src-pages-sitkagreni-tsx" */),
  "component---src-pages-skjolfar-tsx": () => import("./../src/pages/skjolfar.tsx" /* webpackChunkName: "component---src-pages-skjolfar-tsx" */),
  "component---src-pages-stafafura-tsx": () => import("./../src/pages/stafafura.tsx" /* webpackChunkName: "component---src-pages-stafafura-tsx" */),
  "component---src-pages-um-tsx": () => import("./../src/pages/um.tsx" /* webpackChunkName: "component---src-pages-um-tsx" */)
}

